.menu {
  list-style: none;
  margin: 20px;

  li {
    margin: 10px;
    display: inline-block;
    position: relative;
    width: 200px;
    height: 200px;
    background-color: #fafafa;
    border: 5px solid #eaeaea;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
    cursor: pointer;
    border-radius: 2px;
    transition: all 0.4s;

    &:hover {
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.4), inset -100px -100px 200px rgb(11, 205, 170);

      .title {
        color: white;
      }
    }

    .title {
      transition: all 0.4s;
      font-size: 14px;
      position: absolute;
      bottom: 4px;
      left: 0;
      width: 100%;
      text-align: center;
    }
  }
}

.alert {
  text-align: center;
  color: darkred;
}
