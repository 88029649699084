.terminal-bar {
  font-size: 3rem;
  width: 100vw;
  background-color: rgba(black, 0.3);
  height: 14vh;
  color: white;
  z-index: 99;
}

.addon-container {
  z-index: 99;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  .addon-list {
    scroll-behavior: smooth;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: minmax(34vh, auto);

    .addon-background {
      flex: 0 0 auto;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      padding: 40px;

      .addon-card {
        font-size: 1.75vw;
        text-shadow: 2px 4px 8px #1f1f1f;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
      }
    }
  }

  .addon-detail {
    z-index: 10;
    background-size: contain;
    background-position: left;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;

    .addon-info {
      font-size: 160%;
      transform: skew(-10deg);
      height: 100vh;
      width: 65vw;
      position: absolute;
      bottom: 0;
      right: -10vw;

      > * {
        width: 55vw;
        transform: skew(10deg);
      }
    }
  }
}
