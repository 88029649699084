.marketing-container {
  text-align: center;
  color: white;
  margin-top: 8em;

  .marketing-headline {
    font-size: 5em;
    font-weight: 900;
  }

  .marketing-subhead {
    font-size: 2.5em;
    margin: 0.7em 0;
    font-weight: 300;
  }

  .marketing-text {
    font-size: 2.5em;
    font-weight: 500;
  }
}

.marketing-qr {
  position: absolute;
  bottom: 10vh;
  left: 25vw;
  width: 7vw;
  height: 7vw;
}
.marketing-qr-portrait {
  position: absolute;
  top: 22vh;
  left: 64vw;
  width: 7vw;
  height: 7vw;
}
