.courses {
  padding: rem(32);
  display: flex;
  flex-flow: column nowrap;
  max-height: calc(100vh - 168px - 64px);
  width: 100vw;
  overflow: hidden;

  .slide-body {
    display: flex;
    justify-content: center;

    @media all and (orientation: portrait) {
      flex-direction: column;
      align-items: center;
    }

    .course-title {
      font-size: 3em;
      line-height: 1.16667em;
    }

    .course-highlight {
      width: 70%;
      padding: 0 rem(32);
      margin: -16px 0;
      position: relative;
      display: flex;
      flex-direction: column;

      @media all and (orientation: portrait) {
        margin-bottom: 16px;
        width: 100%;
      }

      .card {
        flex-grow: 1;
        width: 100%;
        min-height: rem(200);
        margin: 16px 0;
        position: relative;

        .card-body {
          padding: rem(24) rem(32);
        }

        .course-info {
          font-size: rem(28);
          line-height: rem(32);
        }

        .course-time {
          font-size: rem(40);
          line-height: rem(48);

          img {
            width: rem(48);
          }
        }

        .course-room {
          font-size: rem(32);
          line-height: rem(40);
        }

        .card-aside {
          padding: rem(24) rem(32);
        }

        .card-thumb {
          width: 20%;
        }

        .card-title {
          font-size: rem(52);
          line-height: rem(56);
        }

        .course-instructor {
          font-family: 'Annonce', sans-serif !important;
          font-weight: normal;
          font-size: rem(28);
          line-height: rem(32);
        }
      }
    }

    .course-list {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      flex: 0 0 auto;
      width: 30%;
      flex-flow: column nowrap;
      justify-content: center;

      @media all and (orientation: portrait) {
        margin-bottom: 16px;
        width: 70%;
      }

      li {
        &:not(:last-child) {
          margin-bottom: rem(18);
        }
      }

      .card {
        padding: 0;
        min-height: rem(80);

        .card-thumb {
          width: 130 * 0.25 * 3px;
        }

        .card-body {
          padding: rem(8) rem(16);

          .card-title {
            font-size: rem(20);
            line-height: rem(24);
            margin-top: 0;
          }
        }
      }
    }
  }

  .course-info {
    font-family: 'Annonce', sans-serif !important;
    font-weight: normal;
    white-space: nowrap;

    &:empty {
      display: none;
    }
  }

  .course-room {
    font-weight: bold;
  }

  .card-aside {
    text-align: right;

    img {
      width: rem(24);
      height: auto;
      margin-left: rem(8);
    }

    .course-time {
      line-height: rem(24);
      font-family: 'Annonce', sans-serif !important;
      font-weight: normal;
      white-space: nowrap;
    }

    .course-room {
      font-size: rem(18);
    }
  }
}
