.indicators {
  position: absolute;
  bottom: rem(8);
  left: 0;
  width: 100%;
  text-align: center;
  z-index: 10;

  .indicator {
    display: inline-block;
    cursor: pointer;
    width: rem(8);
    height: rem(8);
    border-radius: 50%;
    background-color: white;
    overflow: hidden;
    text-indent: -1000px;
    color: transparent;
    min-width: inherit;
    padding: 0;
    margin: rem(8);
    opacity: 0.6;

    &.active {
      opacity: 1;
    }
  }
}
