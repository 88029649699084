.slide {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.is-prev {
    z-index: 9;
    opacity: 1;
    transition: all 0.4s;
  }

  &.is-active {
    z-index: 10;
    opacity: 1;
    transition: all 0.4s;
  }
}

.slide-header {
  text-align: center;
  margin: rem(16) 0;
  overflow: hidden;
  text-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 2px 2px rgba(0, 0, 0, 0.2);
  flex: 0 0 auto;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: rem(4) 0;
  }
}

.slide-body {
  padding: rem(32);
  overflow: hidden;
}
