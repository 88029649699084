.setup-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;

  .setup {
    max-width: 50vw;

    h1 {
      font-size: 4em;
      text-align: center;
    }

    .howto-steps {
      font-size: 1.8em;

      li {
        margin-bottom: 1em;
      }
    }

    .id {
      font-family: 'VT323', monospace;
      font-weight: bold;
      text-align: center;
      font-size: 8.2em;
    }
  }
}
