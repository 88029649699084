.employee-list {
  list-style: none;
  display: flex;
  justify-content: center;
  margin: 0 rem(-32);
  padding: 0;
  flex-flow: row wrap;

  li {
    padding: rem(16);
    flex: 0 0 auto;
    width: 33vw;
    justify-content: center;

    @media all and (orientation: portrait) {
      width: 50vw;
    }

    .card {
      min-height: 10em;
      height: 100%;
      align-self: stretch;

      .card-thumb {
        width: 10em;
      }
    }
  }

  &.half {
    transform: scale(1.2);
    width: 60vw;

    @media all and (orientation: portrait) {
      transform: scale(1);
      width: 100vw;
    }

    margin: 0 auto;

    li {
      width: 50%;
      flex-grow: 1;
    }
  }
}

.employee-title {
  font-size: 3em;
  line-height: 1.16667em;
}

.employee-subtitle {
  font-size: 2.5em;
  line-height: 1.2em;
}

.employee-grouped {
  list-style: none;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: 0 rem(-16);

  li {
    width: 25%;
    flex: 0 1 auto;
    padding: 0 rem(16);

    @media all and (orientation: portrait) {
      text-align: center;
      width: 100vw;
    }

    .group-title {
      text-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 2px 2px rgba(0, 0, 0, 0.2);
      margin: rem(4) 0;
      font-size: rem(24);
    }

    .employee-list {
      flex-direction: column;
      margin: 0 rem(-16);

      @media all and (orientation: portrait) {
        flex-direction: row;
      }

      li {
        width: 100%;
        flex: 0 1 auto;
        padding: rem(16);

        @media all and (orientation: portrait) {
          width: 33%;
        }

        .card {
          min-height: 10em;

          .card-thumb {
            width: 10em;
          }

          .card-body {
            .card-title {
              font-size: rem(20);
              line-height: rem(28);
            }

            .card-info {
              font-size: rem(14);
              line-height: rem(20);
            }
          }
        }
      }
    }
  }
}
