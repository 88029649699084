body {
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  .loading {
    width: 100vw;
    height: 100vh;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .footer-version {
    mix-blend-mode: difference;
    color: white;
    text-align: center;
    font-size: 12px;
    position: absolute;
    z-index: 100;
    right: 6px;
    bottom: 0;
  }
}
