@import "https://fonts.googleapis.com/css2?family=Open+Sans&family=VT323&display=swap";
@import "https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;900&display=swap";
body {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

body .loading {
  vertical-align: middle;
  text-align: center;
  width: 100vw;
  height: 100vh;
  display: table-cell;
}

body::-webkit-scrollbar {
  display: none;
}

body .footer-version {
  mix-blend-mode: difference;
  color: #fff;
  text-align: center;
  z-index: 100;
  font-size: 12px;
  position: absolute;
  bottom: 0;
  right: 6px;
}

.menu {
  margin: 20px;
  list-style: none;
}

.menu li {
  cursor: pointer;
  background-color: #fafafa;
  border: 5px solid #eaeaea;
  border-radius: 2px;
  width: 200px;
  height: 200px;
  margin: 10px;
  transition: all .4s;
  display: inline-block;
  position: relative;
  box-shadow: 0 0 8px #0006;
}

.menu li:hover {
  box-shadow: 0 0 8px #0006, inset -100px -100px 200px #0bcdaa;
}

.menu li:hover .title {
  color: #fff;
}

.menu li .title {
  text-align: center;
  width: 100%;
  font-size: 14px;
  transition: all .4s;
  position: absolute;
  bottom: 4px;
  left: 0;
}

.alert {
  text-align: center;
  color: #8b0000;
}

.indicators {
  text-align: center;
  z-index: 10;
  width: 100%;
  position: absolute;
  bottom: .5rem;
  left: 0;
}

.indicators .indicator {
  cursor: pointer;
  text-indent: -1000px;
  color: #0000;
  min-width: inherit;
  opacity: .6;
  background-color: #fff;
  border-radius: 50%;
  width: .5rem;
  height: .5rem;
  margin: .5rem;
  padding: 0;
  display: inline-block;
  overflow: hidden;
}

.indicators .indicator.active {
  opacity: 1;
}

.info-slider {
  width: 100vw;
  height: 100%;
  margin: 0;
  position: relative;
  overflow: hidden;
}

.info-slider .slide-marketing {
  font-family: Montserrat, sans-serif !important;
}

.info-slider .slide-mp4, .info-slider .slide-marketing, .info-slider .slide-course, .info-slider .slide-employee, .info-slider .slide-video, .info-slider .slide-image {
  z-index: -1;
  background-position: center;
  background-size: cover;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.info-slider .bullet-indicator {
  z-index: 99;
  text-align: center;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 4em;
  list-style: none;
  position: absolute;
  bottom: 0;
  left: 0;
}

.info-slider .bullet-indicator li {
  color: #fff;
  opacity: .5;
  text-shadow: 0 0 2px #000;
  margin: 0 4px;
  transition: all .4s;
  display: inline-block;
}

.info-slider .bullet-indicator li.is-active {
  opacity: 1;
  transition: all .4s;
}

.info-slider #identify-screen {
  color: #fff;
  z-index: 999;
  text-align: center;
  background-color: #0006;
  width: 240px;
  height: 160px;
  padding: 24px;
  font-size: 6em;
  line-height: 1;
  position: fixed;
  top: 80px;
  right: 80px;
}

.info-slider .pause-indicator {
  z-index: 1000;
  mix-blend-mode: difference;
  pointer-events: none;
  opacity: .6;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.info-slider .pause-indicator .ixo {
  stroke: #a9a9a9;
  fill: #a9a9a9;
  width: 20rem;
  height: 20rem;
}

.slide {
  opacity: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  transition: all .3s;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.slide.is-prev {
  z-index: 9;
  opacity: 1;
  transition: all .4s;
}

.slide.is-active {
  z-index: 10;
  opacity: 1;
  transition: all .4s;
}

.slide-header {
  text-align: center;
  text-shadow: 0 4px 8px #0003, 0 2px 2px #0003;
  flex: none;
  margin: 1rem 0;
  overflow: hidden;
}

.slide-header h1, .slide-header h2, .slide-header h3, .slide-header h4, .slide-header h5, .slide-header h6 {
  margin: .25rem 0;
}

.slide-body {
  padding: 2rem;
  overflow: hidden;
}

.card {
  background-color: var(--bgColor);
  border-radius: .25rem;
  display: flex;
  overflow: hidden;
  padding: 0 !important;
}

.card.is-theme-light {
  background-color: var(--bgLightColor);
}

.card.is-theme-lightest {
  background-color: var(--bgLightestColor);
}

.card h1, .card h2, .card h3, .card h4, .card h5, .card h6 {
  margin: 0;
}

.card .card-thumb {
  background-color: #0003;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-grow: 0;
  flex-shrink: 0;
}

.card .card-body {
  flex-direction: column;
  flex-grow: 1;
  padding: 1rem;
  display: flex;
}

.card .card-body .card-title {
  -webkit-line-clamp: 2;
  hyphens: auto;
  -webkit-box-orient: vertical;
  font-size: 2.5rem;
  line-height: 3rem;
  display: -webkit-box;
}

.card .card-body p {
  margin: .25rem 0;
}

.card .card-body .card-info {
  flex-wrap: wrap;
  font-size: 1.375rem;
  line-height: 1.75rem;
}

.card .card-aside {
  flex-direction: column;
  justify-content: space-between;
  padding: .5rem;
  display: flex;
}

.hecinfo {
  flex-direction: column;
  height: 100vh;
  display: flex;
  overflow: hidden;
}

.employee-list {
  flex-flow: wrap;
  justify-content: center;
  margin: 0 -2rem;
  padding: 0;
  list-style: none;
  display: flex;
}

.employee-list li {
  flex: none;
  justify-content: center;
  width: 33vw;
  padding: 1rem;
}

@media (orientation: portrait) {
  .employee-list li {
    width: 50vw;
  }
}

.employee-list li .card {
  align-self: stretch;
  height: 100%;
  min-height: 10em;
}

.employee-list li .card .card-thumb {
  width: 10em;
}

.employee-list.half {
  width: 60vw;
  margin: 0 auto;
  transform: scale(1.2);
}

@media (orientation: portrait) {
  .employee-list.half {
    width: 100vw;
    transform: scale(1);
  }
}

.employee-list.half li {
  flex-grow: 1;
  width: 50%;
}

.employee-title {
  font-size: 3em;
  line-height: 1.16667em;
}

.employee-subtitle {
  font-size: 2.5em;
  line-height: 1.2em;
}

.employee-grouped {
  flex-flow: wrap;
  justify-content: center;
  margin: 0 -1rem;
  list-style: none;
  display: flex;
}

.employee-grouped li {
  flex: 0 auto;
  width: 25%;
  padding: 0 1rem;
}

@media (orientation: portrait) {
  .employee-grouped li {
    text-align: center;
    width: 100vw;
  }
}

.employee-grouped li .group-title {
  text-shadow: 0 4px 8px #0003, 0 2px 2px #0003;
  margin: .25rem 0;
  font-size: 1.5rem;
}

.employee-grouped li .employee-list {
  flex-direction: column;
  margin: 0 -1rem;
}

@media (orientation: portrait) {
  .employee-grouped li .employee-list {
    flex-direction: row;
  }
}

.employee-grouped li .employee-list li {
  flex: 0 auto;
  width: 100%;
  padding: 1rem;
}

@media (orientation: portrait) {
  .employee-grouped li .employee-list li {
    width: 33%;
  }
}

.employee-grouped li .employee-list li .card {
  min-height: 10em;
}

.employee-grouped li .employee-list li .card .card-thumb {
  width: 10em;
}

.employee-grouped li .employee-list li .card .card-body .card-title {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.employee-grouped li .employee-list li .card .card-body .card-info {
  font-size: .875rem;
  line-height: 1.25rem;
}

.courses {
  flex-flow: column;
  width: 100vw;
  max-height: calc(100vh - 232px);
  padding: 2rem;
  display: flex;
  overflow: hidden;
}

.courses .slide-body {
  justify-content: center;
  display: flex;
}

@media (orientation: portrait) {
  .courses .slide-body {
    flex-direction: column;
    align-items: center;
  }
}

.courses .slide-body .course-title {
  font-size: 3em;
  line-height: 1.16667em;
}

.courses .slide-body .course-highlight {
  flex-direction: column;
  width: 70%;
  margin: -16px 0;
  padding: 0 2rem;
  display: flex;
  position: relative;
}

@media (orientation: portrait) {
  .courses .slide-body .course-highlight {
    width: 100%;
    margin-bottom: 16px;
  }
}

.courses .slide-body .course-highlight .card {
  flex-grow: 1;
  width: 100%;
  min-height: 12.5rem;
  margin: 16px 0;
  position: relative;
}

.courses .slide-body .course-highlight .card .card-body {
  padding: 1.5rem 2rem;
}

.courses .slide-body .course-highlight .card .course-info {
  font-size: 1.75rem;
  line-height: 2rem;
}

.courses .slide-body .course-highlight .card .course-time {
  font-size: 2.5rem;
  line-height: 3rem;
}

.courses .slide-body .course-highlight .card .course-time img {
  width: 3rem;
}

.courses .slide-body .course-highlight .card .course-room {
  font-size: 2rem;
  line-height: 2.5rem;
}

.courses .slide-body .course-highlight .card .card-aside {
  padding: 1.5rem 2rem;
}

.courses .slide-body .course-highlight .card .card-thumb {
  width: 20%;
}

.courses .slide-body .course-highlight .card .card-title {
  font-size: 3.25rem;
  line-height: 3.5rem;
}

.courses .slide-body .course-highlight .card .course-instructor {
  font-size: 1.75rem;
  font-weight: normal;
  line-height: 2rem;
  font-family: Annonce, sans-serif !important;
}

.courses .slide-body .course-list {
  flex-flow: column;
  flex: none;
  justify-content: center;
  width: 30%;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

@media (orientation: portrait) {
  .courses .slide-body .course-list {
    width: 70%;
    margin-bottom: 16px;
  }
}

.courses .slide-body .course-list li:not(:last-child) {
  margin-bottom: 1.125rem;
}

.courses .slide-body .course-list .card {
  min-height: 5rem;
  padding: 0;
}

.courses .slide-body .course-list .card .card-thumb {
  width: 97.5px;
}

.courses .slide-body .course-list .card .card-body {
  padding: .5rem 1rem;
}

.courses .slide-body .course-list .card .card-body .card-title {
  margin-top: 0;
  font-size: 1.25rem;
  line-height: 1.5rem;
}

.courses .course-info {
  white-space: nowrap;
  font-weight: normal;
  font-family: Annonce, sans-serif !important;
}

.courses .course-info:empty {
  display: none;
}

.courses .course-room {
  font-weight: bold;
}

.courses .card-aside {
  text-align: right;
}

.courses .card-aside img {
  width: 1.5rem;
  height: auto;
  margin-left: .5rem;
}

.courses .card-aside .course-time {
  white-space: nowrap;
  font-weight: normal;
  line-height: 1.5rem;
  font-family: Annonce, sans-serif !important;
}

.courses .card-aside .course-room {
  font-size: 1.125rem;
}

.weather {
  z-index: 99;
  color: #fff;
  background-color: #0000004d;
  flex: none;
  width: 100vw;
}

.weather__big {
  padding: 3vh;
  transform: scale(1.3);
}

.weather__big h1 {
  font-size: 260%;
}

.weather__big .weather-bar {
  transform: scale(1.15);
}

.weather .weather-bar {
  justify-content: center;
  display: flex;
}

.weather .weather-bar__small {
  opacity: .8;
  margin-bottom: -3vh;
  transform: scale(.75);
}

.weather .weather-bar .weather-card {
  flex-direction: column;
  min-width: 20rem;
  padding: 2rem;
  display: flex;
  position: relative;
}

.weather .weather-bar .weather-card:after {
  content: "";
  background-color: #fff9;
  width: .125rem;
  height: 60%;
  position: absolute;
  top: 20%;
  right: -.0625rem;
}

.weather .weather-bar .weather-card:last-child:after {
  display: none;
}

.weather .weather-bar .weather-card .weather-card__title {
  text-align: center;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 2rem;
}

.weather .weather-bar .weather-card .weather-card__info {
  justify-content: center;
  align-items: center;
  margin: 0 -.5rem;
  font-size: 2.5rem;
  line-height: 3rem;
  display: flex;
}

.weather .weather-bar .weather-card .weather-card__info > * {
  padding: .5rem;
}

.weather-ticker {
  mix-blend-mode: difference;
  color: #fff;
  z-index: 99;
  flex: none;
  width: 100vw;
  height: 8vh;
}

.weather-ticker h3 {
  white-space: nowrap;
  letter-spacing: 4px;
  margin: 0;
  padding: 0;
  line-height: 8vh;
  display: inline-block;
}

.weather-ticker h3:after {
  content: "+++";
  margin: 0 10px;
  display: inline-block;
}

@font-face {
  font-family: Annonce;
  src: url("Annonce.cd91fa22.woff2") format("woff2"), url("Annonce.d9229ba5.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: weathericons;
  src: url("weathericons-regular-webfont.d03678e3.woff2") format("woff2"), url("weathericons-regular-webfont.73bd650d.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.wi {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: weathericons;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  display: inline-block;
}

.wi-fw {
  text-align: center;
  width: 1.4em;
}

.wi-rotate-90 {
  filter: progid:DXImageTransform. Microsoft. BasicImage(rotation= 1);
  transform: rotate(90deg);
}

.wi-rotate-180 {
  filter: progid:DXImageTransform. Microsoft. BasicImage(rotation= 2);
  transform: rotate(180deg);
}

.wi-rotate-270 {
  filter: progid:DXImageTransform. Microsoft. BasicImage(rotation= 3);
  transform: rotate(270deg);
}

.wi-flip-horizontal {
  filter: progid:DXImageTransform. Microsoft. BasicImage(rotation= 0, mirror= 1);
  transform: scale(-1, 1);
}

.wi-flip-vertical {
  filter: progid:DXImageTransform. Microsoft. BasicImage(rotation= 2, mirror= 1);
  transform: scale(1, -1);
}

.wi-day-sunny:before {
  content: "";
}

.wi-day-cloudy:before {
  content: "";
}

.wi-day-cloudy-gusts:before {
  content: "";
}

.wi-day-cloudy-windy:before {
  content: "";
}

.wi-day-fog:before {
  content: "";
}

.wi-day-hail:before {
  content: "";
}

.wi-day-haze:before {
  content: "";
}

.wi-day-lightning:before {
  content: "";
}

.wi-day-rain:before {
  content: "";
}

.wi-day-rain-mix:before {
  content: "";
}

.wi-day-rain-wind:before {
  content: "";
}

.wi-day-showers:before {
  content: "";
}

.wi-day-sleet:before {
  content: "";
}

.wi-day-sleet-storm:before {
  content: "";
}

.wi-day-snow:before {
  content: "";
}

.wi-day-snow-thunderstorm:before {
  content: "";
}

.wi-day-snow-wind:before {
  content: "";
}

.wi-day-sprinkle:before {
  content: "";
}

.wi-day-storm-showers:before {
  content: "";
}

.wi-day-sunny-overcast:before {
  content: "";
}

.wi-day-thunderstorm:before {
  content: "";
}

.wi-day-windy:before {
  content: "";
}

.wi-solar-eclipse:before {
  content: "";
}

.wi-hot:before {
  content: "";
}

.wi-day-cloudy-high:before {
  content: "";
}

.wi-day-light-wind:before {
  content: "";
}

.wi-night-clear:before {
  content: "";
}

.wi-night-alt-cloudy:before {
  content: "";
}

.wi-night-alt-cloudy-gusts:before {
  content: "";
}

.wi-night-alt-cloudy-windy:before {
  content: "";
}

.wi-night-alt-hail:before {
  content: "";
}

.wi-night-alt-lightning:before {
  content: "";
}

.wi-night-alt-rain:before {
  content: "";
}

.wi-night-alt-rain-mix:before {
  content: "";
}

.wi-night-alt-rain-wind:before {
  content: "";
}

.wi-night-alt-showers:before {
  content: "";
}

.wi-night-alt-sleet:before {
  content: "";
}

.wi-night-alt-sleet-storm:before {
  content: "";
}

.wi-night-alt-snow:before {
  content: "";
}

.wi-night-alt-snow-thunderstorm:before {
  content: "";
}

.wi-night-alt-snow-wind:before {
  content: "";
}

.wi-night-alt-sprinkle:before {
  content: "";
}

.wi-night-alt-storm-showers:before {
  content: "";
}

.wi-night-alt-thunderstorm:before {
  content: "";
}

.wi-night-cloudy:before {
  content: "";
}

.wi-night-cloudy-gusts:before {
  content: "";
}

.wi-night-cloudy-windy:before {
  content: "";
}

.wi-night-fog:before {
  content: "";
}

.wi-night-hail:before {
  content: "";
}

.wi-night-lightning:before {
  content: "";
}

.wi-night-partly-cloudy:before {
  content: "";
}

.wi-night-rain:before {
  content: "";
}

.wi-night-rain-mix:before {
  content: "";
}

.wi-night-rain-wind:before {
  content: "";
}

.wi-night-showers:before {
  content: "";
}

.wi-night-sleet:before {
  content: "";
}

.wi-night-sleet-storm:before {
  content: "";
}

.wi-night-snow:before {
  content: "";
}

.wi-night-snow-thunderstorm:before {
  content: "";
}

.wi-night-snow-wind:before {
  content: "";
}

.wi-night-sprinkle:before {
  content: "";
}

.wi-night-storm-showers:before {
  content: "";
}

.wi-night-thunderstorm:before {
  content: "";
}

.wi-lunar-eclipse:before {
  content: "";
}

.wi-stars:before {
  content: "";
}

.wi-night-alt-cloudy-high:before {
  content: "";
}

.wi-night-cloudy-high:before {
  content: "";
}

.wi-night-alt-partly-cloudy:before {
  content: "";
}

.wi-cloud:before {
  content: "";
}

.wi-cloudy:before {
  content: "";
}

.wi-cloudy-gusts:before {
  content: "";
}

.wi-cloudy-windy:before {
  content: "";
}

.wi-fog:before {
  content: "";
}

.wi-hail:before {
  content: "";
}

.wi-rain:before {
  content: "";
}

.wi-rain-mix:before {
  content: "";
}

.wi-rain-wind:before {
  content: "";
}

.wi-showers:before {
  content: "";
}

.wi-sleet:before {
  content: "";
}

.wi-sprinkle:before {
  content: "";
}

.wi-storm-showers:before {
  content: "";
}

.wi-thunderstorm:before {
  content: "";
}

.wi-snow-wind:before {
  content: "";
}

.wi-snow:before {
  content: "";
}

.wi-smog:before {
  content: "";
}

.wi-smoke:before {
  content: "";
}

.wi-lightning:before {
  content: "";
}

.wi-raindrops:before {
  content: "";
}

.wi-raindrop:before {
  content: "";
}

.wi-dust:before {
  content: "";
}

.wi-snowflake-cold:before {
  content: "";
}

.wi-windy:before {
  content: "";
}

.wi-strong-wind:before {
  content: "";
}

.wi-sandstorm:before {
  content: "";
}

.wi-earthquake:before {
  content: "";
}

.wi-fire:before {
  content: "";
}

.wi-flood:before {
  content: "";
}

.wi-meteor:before {
  content: "";
}

.wi-tsunami:before {
  content: "";
}

.wi-volcano:before {
  content: "";
}

.wi-hurricane:before {
  content: "";
}

.wi-tornado:before {
  content: "";
}

.wi-small-craft-advisory:before {
  content: "";
}

.wi-gale-warning:before {
  content: "";
}

.wi-storm-warning:before {
  content: "";
}

.wi-hurricane-warning:before {
  content: "";
}

.wi-wind-direction:before {
  content: "";
}

.wi-alien:before {
  content: "";
}

.wi-celsius:before {
  content: "";
}

.wi-fahrenheit:before {
  content: "";
}

.wi-degrees:before {
  content: "";
}

.wi-thermometer:before {
  content: "";
}

.wi-thermometer-exterior:before {
  content: "";
}

.wi-thermometer-internal:before {
  content: "";
}

.wi-cloud-down:before {
  content: "";
}

.wi-cloud-up:before {
  content: "";
}

.wi-cloud-refresh:before {
  content: "";
}

.wi-horizon:before {
  content: "";
}

.wi-horizon-alt:before {
  content: "";
}

.wi-sunrise:before {
  content: "";
}

.wi-sunset:before {
  content: "";
}

.wi-moonrise:before {
  content: "";
}

.wi-moonset:before {
  content: "";
}

.wi-refresh:before {
  content: "";
}

.wi-refresh-alt:before {
  content: "";
}

.wi-umbrella:before {
  content: "";
}

.wi-barometer:before {
  content: "";
}

.wi-humidity:before {
  content: "";
}

.wi-na:before {
  content: "";
}

.wi-train:before {
  content: "";
}

.wi-moon-new:before {
  content: "";
}

.wi-moon-waxing-crescent-1:before {
  content: "";
}

.wi-moon-waxing-crescent-2:before {
  content: "";
}

.wi-moon-waxing-crescent-3:before {
  content: "";
}

.wi-moon-waxing-crescent-4:before {
  content: "";
}

.wi-moon-waxing-crescent-5:before {
  content: "";
}

.wi-moon-waxing-crescent-6:before {
  content: "";
}

.wi-moon-first-quarter:before {
  content: "";
}

.wi-moon-waxing-gibbous-1:before {
  content: "";
}

.wi-moon-waxing-gibbous-2:before {
  content: "";
}

.wi-moon-waxing-gibbous-3:before {
  content: "";
}

.wi-moon-waxing-gibbous-4:before {
  content: "";
}

.wi-moon-waxing-gibbous-5:before {
  content: "";
}

.wi-moon-waxing-gibbous-6:before {
  content: "";
}

.wi-moon-full:before {
  content: "";
}

.wi-moon-waning-gibbous-1:before {
  content: "";
}

.wi-moon-waning-gibbous-2:before {
  content: "";
}

.wi-moon-waning-gibbous-3:before {
  content: "";
}

.wi-moon-waning-gibbous-4:before {
  content: "";
}

.wi-moon-waning-gibbous-5:before {
  content: "";
}

.wi-moon-waning-gibbous-6:before {
  content: "";
}

.wi-moon-third-quarter:before {
  content: "";
}

.wi-moon-waning-crescent-1:before {
  content: "";
}

.wi-moon-waning-crescent-2:before {
  content: "";
}

.wi-moon-waning-crescent-3:before {
  content: "";
}

.wi-moon-waning-crescent-4:before {
  content: "";
}

.wi-moon-waning-crescent-5:before {
  content: "";
}

.wi-moon-waning-crescent-6:before {
  content: "";
}

.wi-moon-alt-new:before {
  content: "";
}

.wi-moon-alt-waxing-crescent-1:before {
  content: "";
}

.wi-moon-alt-waxing-crescent-2:before {
  content: "";
}

.wi-moon-alt-waxing-crescent-3:before {
  content: "";
}

.wi-moon-alt-waxing-crescent-4:before {
  content: "";
}

.wi-moon-alt-waxing-crescent-5:before {
  content: "";
}

.wi-moon-alt-waxing-crescent-6:before {
  content: "";
}

.wi-moon-alt-first-quarter:before {
  content: "";
}

.wi-moon-alt-waxing-gibbous-1:before {
  content: "";
}

.wi-moon-alt-waxing-gibbous-2:before {
  content: "";
}

.wi-moon-alt-waxing-gibbous-3:before {
  content: "";
}

.wi-moon-alt-waxing-gibbous-4:before {
  content: "";
}

.wi-moon-alt-waxing-gibbous-5:before {
  content: "";
}

.wi-moon-alt-waxing-gibbous-6:before {
  content: "";
}

.wi-moon-alt-full:before {
  content: "";
}

.wi-moon-alt-waning-gibbous-1:before {
  content: "";
}

.wi-moon-alt-waning-gibbous-2:before {
  content: "";
}

.wi-moon-alt-waning-gibbous-3:before {
  content: "";
}

.wi-moon-alt-waning-gibbous-4:before {
  content: "";
}

.wi-moon-alt-waning-gibbous-5:before {
  content: "";
}

.wi-moon-alt-waning-gibbous-6:before {
  content: "";
}

.wi-moon-alt-third-quarter:before {
  content: "";
}

.wi-moon-alt-waning-crescent-1:before {
  content: "";
}

.wi-moon-alt-waning-crescent-2:before {
  content: "";
}

.wi-moon-alt-waning-crescent-3:before {
  content: "";
}

.wi-moon-alt-waning-crescent-4:before {
  content: "";
}

.wi-moon-alt-waning-crescent-5:before {
  content: "";
}

.wi-moon-alt-waning-crescent-6:before {
  content: "";
}

.wi-moon-0:before {
  content: "";
}

.wi-moon-1:before {
  content: "";
}

.wi-moon-2:before {
  content: "";
}

.wi-moon-3:before {
  content: "";
}

.wi-moon-4:before {
  content: "";
}

.wi-moon-5:before {
  content: "";
}

.wi-moon-6:before {
  content: "";
}

.wi-moon-7:before {
  content: "";
}

.wi-moon-8:before {
  content: "";
}

.wi-moon-9:before {
  content: "";
}

.wi-moon-10:before {
  content: "";
}

.wi-moon-11:before {
  content: "";
}

.wi-moon-12:before {
  content: "";
}

.wi-moon-13:before {
  content: "";
}

.wi-moon-14:before {
  content: "";
}

.wi-moon-15:before {
  content: "";
}

.wi-moon-16:before {
  content: "";
}

.wi-moon-17:before {
  content: "";
}

.wi-moon-18:before {
  content: "";
}

.wi-moon-19:before {
  content: "";
}

.wi-moon-20:before {
  content: "";
}

.wi-moon-21:before {
  content: "";
}

.wi-moon-22:before {
  content: "";
}

.wi-moon-23:before {
  content: "";
}

.wi-moon-24:before {
  content: "";
}

.wi-moon-25:before {
  content: "";
}

.wi-moon-26:before {
  content: "";
}

.wi-moon-27:before {
  content: "";
}

.wi-time-1:before {
  content: "";
}

.wi-time-2:before {
  content: "";
}

.wi-time-3:before {
  content: "";
}

.wi-time-4:before {
  content: "";
}

.wi-time-5:before {
  content: "";
}

.wi-time-6:before {
  content: "";
}

.wi-time-7:before {
  content: "";
}

.wi-time-8:before {
  content: "";
}

.wi-time-9:before {
  content: "";
}

.wi-time-10:before {
  content: "";
}

.wi-time-11:before {
  content: "";
}

.wi-time-12:before {
  content: "";
}

.wi-direction-up:before {
  content: "";
}

.wi-direction-up-right:before {
  content: "";
}

.wi-direction-right:before {
  content: "";
}

.wi-direction-down-right:before {
  content: "";
}

.wi-direction-down:before {
  content: "";
}

.wi-direction-down-left:before {
  content: "";
}

.wi-direction-left:before {
  content: "";
}

.wi-direction-up-left:before {
  content: "";
}

.wi-wind-beaufort-0:before {
  content: "";
}

.wi-wind-beaufort-1:before {
  content: "";
}

.wi-wind-beaufort-2:before {
  content: "";
}

.wi-wind-beaufort-3:before {
  content: "";
}

.wi-wind-beaufort-4:before {
  content: "";
}

.wi-wind-beaufort-5:before {
  content: "";
}

.wi-wind-beaufort-6:before {
  content: "";
}

.wi-wind-beaufort-7:before {
  content: "";
}

.wi-wind-beaufort-8:before {
  content: "";
}

.wi-wind-beaufort-9:before {
  content: "";
}

.wi-wind-beaufort-10:before {
  content: "";
}

.wi-wind-beaufort-11:before {
  content: "";
}

.wi-wind-beaufort-12:before {
  content: "";
}

.wi-yahoo-0:before {
  content: "";
}

.wi-yahoo-1:before {
  content: "";
}

.wi-yahoo-2:before {
  content: "";
}

.wi-yahoo-3:before, .wi-yahoo-4:before {
  content: "";
}

.wi-yahoo-5:before, .wi-yahoo-6:before, .wi-yahoo-7:before {
  content: "";
}

.wi-yahoo-8:before {
  content: "";
}

.wi-yahoo-9:before {
  content: "";
}

.wi-yahoo-10:before {
  content: "";
}

.wi-yahoo-11:before, .wi-yahoo-12:before {
  content: "";
}

.wi-yahoo-13:before {
  content: "";
}

.wi-yahoo-14:before {
  content: "";
}

.wi-yahoo-15:before {
  content: "";
}

.wi-yahoo-16:before {
  content: "";
}

.wi-yahoo-17:before {
  content: "";
}

.wi-yahoo-18:before {
  content: "";
}

.wi-yahoo-19:before {
  content: "";
}

.wi-yahoo-20:before {
  content: "";
}

.wi-yahoo-21:before {
  content: "";
}

.wi-yahoo-22:before {
  content: "";
}

.wi-yahoo-23:before, .wi-yahoo-24:before {
  content: "";
}

.wi-yahoo-25:before {
  content: "";
}

.wi-yahoo-26:before {
  content: "";
}

.wi-yahoo-27:before {
  content: "";
}

.wi-yahoo-28:before {
  content: "";
}

.wi-yahoo-29:before {
  content: "";
}

.wi-yahoo-30:before {
  content: "";
}

.wi-yahoo-31:before {
  content: "";
}

.wi-yahoo-32:before {
  content: "";
}

.wi-yahoo-33:before {
  content: "";
}

.wi-yahoo-34:before {
  content: "";
}

.wi-yahoo-35:before {
  content: "";
}

.wi-yahoo-36:before {
  content: "";
}

.wi-yahoo-37:before, .wi-yahoo-38:before, .wi-yahoo-39:before {
  content: "";
}

.wi-yahoo-40:before {
  content: "";
}

.wi-yahoo-41:before {
  content: "";
}

.wi-yahoo-42:before {
  content: "";
}

.wi-yahoo-43:before {
  content: "";
}

.wi-yahoo-44:before {
  content: "";
}

.wi-yahoo-45:before {
  content: "";
}

.wi-yahoo-46:before {
  content: "";
}

.wi-yahoo-47:before {
  content: "";
}

.wi-yahoo-3200:before {
  content: "";
}

.wi-forecast-io-clear-day:before {
  content: "";
}

.wi-forecast-io-clear-night:before {
  content: "";
}

.wi-forecast-io-rain:before {
  content: "";
}

.wi-forecast-io-snow:before {
  content: "";
}

.wi-forecast-io-sleet:before {
  content: "";
}

.wi-forecast-io-wind:before {
  content: "";
}

.wi-forecast-io-fog:before {
  content: "";
}

.wi-forecast-io-cloudy:before {
  content: "";
}

.wi-forecast-io-partly-cloudy-day:before {
  content: "";
}

.wi-forecast-io-partly-cloudy-night:before {
  content: "";
}

.wi-forecast-io-hail:before {
  content: "";
}

.wi-forecast-io-thunderstorm:before {
  content: "";
}

.wi-forecast-io-tornado:before {
  content: "";
}

.wi-wmo4680-0:before, .wi-wmo4680-00:before {
  content: "";
}

.wi-wmo4680-1:before, .wi-wmo4680-01:before {
  content: "";
}

.wi-wmo4680-2:before, .wi-wmo4680-02:before {
  content: "";
}

.wi-wmo4680-3:before, .wi-wmo4680-03:before {
  content: "";
}

.wi-wmo4680-4:before, .wi-wmo4680-04:before, .wi-wmo4680-5:before, .wi-wmo4680-05:before, .wi-wmo4680-10:before, .wi-wmo4680-11:before {
  content: "";
}

.wi-wmo4680-12:before {
  content: "";
}

.wi-wmo4680-18:before {
  content: "";
}

.wi-wmo4680-20:before {
  content: "";
}

.wi-wmo4680-21:before, .wi-wmo4680-22:before {
  content: "";
}

.wi-wmo4680-23:before {
  content: "";
}

.wi-wmo4680-24:before {
  content: "";
}

.wi-wmo4680-25:before {
  content: "";
}

.wi-wmo4680-26:before {
  content: "";
}

.wi-wmo4680-27:before, .wi-wmo4680-28:before, .wi-wmo4680-29:before {
  content: "";
}

.wi-wmo4680-30:before, .wi-wmo4680-31:before, .wi-wmo4680-32:before, .wi-wmo4680-33:before, .wi-wmo4680-34:before, .wi-wmo4680-35:before {
  content: "";
}

.wi-wmo4680-40:before {
  content: "";
}

.wi-wmo4680-41:before {
  content: "";
}

.wi-wmo4680-42:before {
  content: "";
}

.wi-wmo4680-43:before {
  content: "";
}

.wi-wmo4680-44:before {
  content: "";
}

.wi-wmo4680-45:before, .wi-wmo4680-46:before {
  content: "";
}

.wi-wmo4680-47:before, .wi-wmo4680-48:before {
  content: "";
}

.wi-wmo4680-50:before, .wi-wmo4680-51:before {
  content: "";
}

.wi-wmo4680-52:before, .wi-wmo4680-53:before {
  content: "";
}

.wi-wmo4680-54:before, .wi-wmo4680-55:before, .wi-wmo4680-56:before {
  content: "";
}

.wi-wmo4680-57:before {
  content: "";
}

.wi-wmo4680-58:before {
  content: "";
}

.wi-wmo4680-60:before, .wi-wmo4680-61:before {
  content: "";
}

.wi-wmo4680-62:before, .wi-wmo4680-63:before {
  content: "";
}

.wi-wmo4680-64:before, .wi-wmo4680-65:before, .wi-wmo4680-66:before {
  content: "";
}

.wi-wmo4680-67:before, .wi-wmo4680-68:before {
  content: "";
}

.wi-wmo4680-70:before, .wi-wmo4680-71:before, .wi-wmo4680-72:before, .wi-wmo4680-73:before {
  content: "";
}

.wi-wmo4680-74:before, .wi-wmo4680-75:before, .wi-wmo4680-76:before {
  content: "";
}

.wi-wmo4680-77:before {
  content: "";
}

.wi-wmo4680-78:before {
  content: "";
}

.wi-wmo4680-80:before {
  content: "";
}

.wi-wmo4680-81:before {
  content: "";
}

.wi-wmo4680-82:before, .wi-wmo4680-83:before {
  content: "";
}

.wi-wmo4680-84:before {
  content: "";
}

.wi-wmo4680-85:before, .wi-wmo4680-86:before, .wi-wmo4680-87:before {
  content: "";
}

.wi-wmo4680-89:before {
  content: "";
}

.wi-wmo4680-90:before {
  content: "";
}

.wi-wmo4680-91:before {
  content: "";
}

.wi-wmo4680-92:before, .wi-wmo4680-93:before {
  content: "";
}

.wi-wmo4680-94:before {
  content: "";
}

.wi-wmo4680-95:before, .wi-wmo4680-96:before {
  content: "";
}

.wi-wmo4680-99:before {
  content: "";
}

.wi-owm-200:before, .wi-owm-201:before, .wi-owm-202:before {
  content: "";
}

.wi-owm-210:before, .wi-owm-211:before, .wi-owm-212:before, .wi-owm-221:before {
  content: "";
}

.wi-owm-230:before, .wi-owm-231:before, .wi-owm-232:before {
  content: "";
}

.wi-owm-300:before, .wi-owm-301:before {
  content: "";
}

.wi-owm-302:before {
  content: "";
}

.wi-owm-310:before {
  content: "";
}

.wi-owm-311:before, .wi-owm-312:before {
  content: "";
}

.wi-owm-313:before {
  content: "";
}

.wi-owm-314:before {
  content: "";
}

.wi-owm-321:before, .wi-owm-500:before {
  content: "";
}

.wi-owm-501:before, .wi-owm-502:before, .wi-owm-503:before, .wi-owm-504:before {
  content: "";
}

.wi-owm-511:before {
  content: "";
}

.wi-owm-520:before, .wi-owm-521:before, .wi-owm-522:before {
  content: "";
}

.wi-owm-531:before {
  content: "";
}

.wi-owm-600:before, .wi-owm-601:before {
  content: "";
}

.wi-owm-602:before {
  content: "";
}

.wi-owm-611:before, .wi-owm-612:before, .wi-owm-615:before, .wi-owm-616:before, .wi-owm-620:before {
  content: "";
}

.wi-owm-621:before, .wi-owm-622:before {
  content: "";
}

.wi-owm-701:before {
  content: "";
}

.wi-owm-711:before {
  content: "";
}

.wi-owm-721:before {
  content: "";
}

.wi-owm-731:before {
  content: "";
}

.wi-owm-741:before {
  content: "";
}

.wi-owm-761:before, .wi-owm-762:before {
  content: "";
}

.wi-owm-771:before {
  content: "";
}

.wi-owm-781:before {
  content: "";
}

.wi-owm-800:before {
  content: "";
}

.wi-owm-801:before, .wi-owm-802:before {
  content: "";
}

.wi-owm-803:before {
  content: "";
}

.wi-owm-804:before {
  content: "";
}

.wi-owm-900:before {
  content: "";
}

.wi-owm-901:before {
  content: "";
}

.wi-owm-902:before {
  content: "";
}

.wi-owm-903:before {
  content: "";
}

.wi-owm-904:before {
  content: "";
}

.wi-owm-905:before {
  content: "";
}

.wi-owm-906:before {
  content: "";
}

.wi-owm-957:before {
  content: "";
}

.wi-owm-day-200:before, .wi-owm-day-201:before, .wi-owm-day-202:before {
  content: "";
}

.wi-owm-day-210:before, .wi-owm-day-211:before, .wi-owm-day-212:before, .wi-owm-day-221:before {
  content: "";
}

.wi-owm-day-230:before, .wi-owm-day-231:before, .wi-owm-day-232:before {
  content: "";
}

.wi-owm-day-300:before, .wi-owm-day-301:before {
  content: "";
}

.wi-owm-day-302:before, .wi-owm-day-310:before, .wi-owm-day-311:before, .wi-owm-day-312:before, .wi-owm-day-313:before, .wi-owm-day-314:before {
  content: "";
}

.wi-owm-day-321:before, .wi-owm-day-500:before {
  content: "";
}

.wi-owm-day-501:before, .wi-owm-day-502:before, .wi-owm-day-503:before, .wi-owm-day-504:before {
  content: "";
}

.wi-owm-day-511:before {
  content: "";
}

.wi-owm-day-520:before, .wi-owm-day-521:before, .wi-owm-day-522:before {
  content: "";
}

.wi-owm-day-531:before {
  content: "";
}

.wi-owm-day-600:before {
  content: "";
}

.wi-owm-day-601:before {
  content: "";
}

.wi-owm-day-602:before {
  content: "";
}

.wi-owm-day-611:before, .wi-owm-day-612:before, .wi-owm-day-615:before, .wi-owm-day-616:before, .wi-owm-day-620:before {
  content: "";
}

.wi-owm-day-621:before, .wi-owm-day-622:before {
  content: "";
}

.wi-owm-day-701:before {
  content: "";
}

.wi-owm-day-711:before {
  content: "";
}

.wi-owm-day-721:before {
  content: "";
}

.wi-owm-day-731:before {
  content: "";
}

.wi-owm-day-741:before {
  content: "";
}

.wi-owm-day-761:before, .wi-owm-day-762:before {
  content: "";
}

.wi-owm-day-781:before {
  content: "";
}

.wi-owm-day-800:before {
  content: "";
}

.wi-owm-day-801:before, .wi-owm-day-802:before, .wi-owm-day-803:before {
  content: "";
}

.wi-owm-day-804:before {
  content: "";
}

.wi-owm-day-900:before {
  content: "";
}

.wi-owm-day-902:before {
  content: "";
}

.wi-owm-day-903:before {
  content: "";
}

.wi-owm-day-904:before {
  content: "";
}

.wi-owm-day-906:before {
  content: "";
}

.wi-owm-day-957:before {
  content: "";
}

.wi-owm-night-200:before, .wi-owm-night-201:before, .wi-owm-night-202:before {
  content: "";
}

.wi-owm-night-210:before, .wi-owm-night-211:before, .wi-owm-night-212:before, .wi-owm-night-221:before {
  content: "";
}

.wi-owm-night-230:before, .wi-owm-night-231:before, .wi-owm-night-232:before {
  content: "";
}

.wi-owm-night-300:before, .wi-owm-night-301:before {
  content: "";
}

.wi-owm-night-302:before, .wi-owm-night-310:before, .wi-owm-night-311:before, .wi-owm-night-312:before, .wi-owm-night-313:before, .wi-owm-night-314:before {
  content: "";
}

.wi-owm-night-321:before, .wi-owm-night-500:before {
  content: "";
}

.wi-owm-night-501:before, .wi-owm-night-502:before, .wi-owm-night-503:before, .wi-owm-night-504:before {
  content: "";
}

.wi-owm-night-511:before {
  content: "";
}

.wi-owm-night-520:before, .wi-owm-night-521:before, .wi-owm-night-522:before {
  content: "";
}

.wi-owm-night-531:before {
  content: "";
}

.wi-owm-night-600:before {
  content: "";
}

.wi-owm-night-601:before {
  content: "";
}

.wi-owm-night-602:before {
  content: "";
}

.wi-owm-night-611:before, .wi-owm-night-612:before, .wi-owm-night-615:before, .wi-owm-night-616:before, .wi-owm-night-620:before {
  content: "";
}

.wi-owm-night-621:before, .wi-owm-night-622:before {
  content: "";
}

.wi-owm-night-701:before {
  content: "";
}

.wi-owm-night-711:before {
  content: "";
}

.wi-owm-night-721:before {
  content: "";
}

.wi-owm-night-731:before {
  content: "";
}

.wi-owm-night-741:before {
  content: "";
}

.wi-owm-night-761:before, .wi-owm-night-762:before {
  content: "";
}

.wi-owm-night-781:before {
  content: "";
}

.wi-owm-night-800:before {
  content: "";
}

.wi-owm-night-801:before, .wi-owm-night-802:before, .wi-owm-night-803:before {
  content: "";
}

.wi-owm-night-804:before {
  content: "";
}

.wi-owm-night-900:before {
  content: "";
}

.wi-owm-night-902:before {
  content: "";
}

.wi-owm-night-903:before {
  content: "";
}

.wi-owm-night-904:before {
  content: "";
}

.wi-owm-night-906:before {
  content: "";
}

.wi-owm-night-957:before {
  content: "";
}

.wi-wu-chanceflurries:before {
  content: "";
}

.wi-wu-chancerain:before {
  content: "";
}

.wi-wu-chancesleat:before {
  content: "";
}

.wi-wu-chancesnow:before {
  content: "";
}

.wi-wu-chancetstorms:before {
  content: "";
}

.wi-wu-clear:before {
  content: "";
}

.wi-wu-cloudy:before {
  content: "";
}

.wi-wu-flurries:before {
  content: "";
}

.wi-wu-hazy:before {
  content: "";
}

.wi-wu-mostlycloudy:before {
  content: "";
}

.wi-wu-mostlysunny:before {
  content: "";
}

.wi-wu-partlycloudy:before {
  content: "";
}

.wi-wu-partlysunny:before {
  content: "";
}

.wi-wu-rain:before {
  content: "";
}

.wi-wu-sleat:before {
  content: "";
}

.wi-wu-snow:before {
  content: "";
}

.wi-wu-sunny:before {
  content: "";
}

.wi-wu-tstorms:before {
  content: "";
}

.wi-wu-unknown:before {
  content: "";
}

.news {
  mix-blend-mode: difference;
  color: #fff;
  z-index: 99;
  flex: none;
  width: 100vw;
  height: 8vh;
}

.news h1, .news h2, .news h3, .news h4, .news h5, .news h6 {
  font-family: Helvetica, sans-serif !important;
}

.news h3 {
  white-space: nowrap;
  letter-spacing: 4px;
  margin: 0;
  padding: 0;
  line-height: 8vh;
  display: inline-block;
}

.news h3:after {
  content: "+++";
  margin: 0 10px;
  display: inline-block;
}

.setup-container {
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  display: flex;
}

.setup-container .setup {
  max-width: 50vw;
}

.setup-container .setup h1 {
  text-align: center;
  font-size: 4em;
}

.setup-container .setup .howto-steps {
  font-size: 1.8em;
}

.setup-container .setup .howto-steps li {
  margin-bottom: 1em;
}

.setup-container .setup .id {
  text-align: center;
  font-family: VT323, monospace;
  font-size: 8.2em;
  font-weight: bold;
}

.terminal-bar {
  color: #fff;
  z-index: 99;
  background-color: #0000004d;
  width: 100vw;
  height: 14vh;
  font-size: 3rem;
}

.addon-container {
  z-index: 99;
  width: 100vw;
  height: 100vh;
  position: absolute;
  inset: 0;
}

.addon-container .addon-list {
  scroll-behavior: smooth;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: minmax(34vh, auto);
  display: grid;
}

.addon-container .addon-list .addon-background {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex: none;
  padding: 40px;
}

.addon-container .addon-list .addon-background .addon-card {
  text-shadow: 2px 4px 8px #1f1f1f;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 1.75vw;
  display: flex;
}

.addon-container .addon-detail {
  z-index: 10;
  background-position: 0;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.addon-container .addon-detail .addon-info {
  width: 65vw;
  height: 100vh;
  font-size: 160%;
  position: absolute;
  bottom: 0;
  right: -10vw;
  transform: skew(-10deg);
}

.addon-container .addon-detail .addon-info > * {
  width: 55vw;
  transform: skew(10deg);
}

.marketing-container {
  text-align: center;
  color: #fff;
  margin-top: 8em;
}

.marketing-container .marketing-headline {
  font-size: 5em;
  font-weight: 900;
}

.marketing-container .marketing-subhead {
  margin: .7em 0;
  font-size: 2.5em;
  font-weight: 300;
}

.marketing-container .marketing-text {
  font-size: 2.5em;
  font-weight: 500;
}

.marketing-qr {
  width: 7vw;
  height: 7vw;
  position: absolute;
  bottom: 10vh;
  left: 25vw;
}

.marketing-qr-portrait {
  width: 7vw;
  height: 7vw;
  position: absolute;
  top: 22vh;
  left: 64vw;
}

html, body {
  font-family: Open Sans, sans-serif;
  font-size: 16px !important;
}

@media (width >= 121em) {
  html, body {
    font-size: 32px !important;
  }
}

.is-hidden {
  visibility: hidden !important;
  opacity: 0 !important;
  display: none !important;
}

.ixo {
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
}
/*# sourceMappingURL=index.3ed79fe0.css.map */
