.weather {
  z-index: 99;
  flex: 0 0 auto;
  color: white;
  width: 100vw;
  background-color: rgba(black, 0.3);

  &__big {
    transform: scale(1.3);
    padding: 3vh;

    h1 {
      font-size: 260%;
    }

    .weather-bar {
      transform: scale(1.15);
    }
  }

  .weather-bar {
    display: flex;
    justify-content: center;

    &__small {
      opacity: 0.8;
      transform: scale(0.75);
      margin-bottom: -3vh;
    }

    .weather-card {
      display: flex;
      flex-direction: column;
      padding: rem(32);
      min-width: rem(320);
      position: relative;

      &:after {
        height: 60%;
        width: rem(2);
        background-color: rgba(white, 0.6);
        position: absolute;
        right: rem(-1);
        content: '';
        top: 20%;
      }

      &:last-child {
        &:after {
          display: none;
        }
      }

      .weather-card__title {
        font-size: rem(24);
        line-height: rem(32);
        font-weight: bold;
        text-align: center;
        margin-bottom: rem(8);
      }

      .weather-card__info {
        font-size: rem(40);
        line-height: rem(48);
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 rem(-8);

        > * {
          padding: rem(8);
        }
      }
    }
  }
}

.weather-ticker {
  width: 100vw;
  height: 8vh;
  mix-blend-mode: difference;
  color: white;
  z-index: 99;
  flex: 0 0 auto;

  h3 {
    display: inline-block;
    white-space: nowrap;
    line-height: 8vh;
    margin: 0;
    padding: 0;
    letter-spacing: 4px;

    &:after {
      display: inline-block;
      margin: 0 10px;
      content: '+++';
    }
  }
}
