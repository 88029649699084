.news {
  width: 100vw;
  height: 8vh;
  mix-blend-mode: difference;
  color: white;
  z-index: 99;
  flex: 0 0 auto;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Helvetica', sans-serif !important;
  }

  h3 {
    display: inline-block;
    white-space: nowrap;
    line-height: 8vh;
    margin: 0;
    padding: 0;
    letter-spacing: 4px;

    &:after {
      display: inline-block;
      margin: 0 10px;
      content: '+++';
    }
  }
}
