.card {
  background-color: var(--bgColor);
  display: flex;
  border-radius: rem(4);
  overflow: hidden;
  padding: 0 !important;

  &.is-theme-light {
    background-color: var(--bgLightColor);
  }

  &.is-theme-lightest {
    background-color: var(--bgLightestColor);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  .card-thumb {
    flex-grow: 0;
    flex-shrink: 0;
    background-color: rgba(black, 0.2);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .card-body {
    padding: rem(16);
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .card-title {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      font-size: rem(40);
      line-height: rem(48);
      hyphens: auto;
    }

    p {
      margin: rem(4) 0;
    }

    .card-info {
      flex-wrap: wrap;
      font-size: rem(22);
      line-height: rem(28);
    }
  }

  .card-aside {
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    padding: rem(8);
  }
}
