@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;900&display=swap');

.info-slider {
  height: 100%;
  width: 100vw;
  margin: 0;
  position: relative;
  overflow: hidden;

  .slide-marketing {
    font-family: 'Montserrat', sans-serif !important;
  }

  .slide-mp4,
  .slide-marketing,
  .slide-course,
  .slide-employee,
  .slide-video,
  .slide-image {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-position: center center;
  }

  .bullet-indicator {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 99;
    margin: 0;
    padding: 0;
    list-style: none;
    width: 100%;
    text-align: center;
    font-size: 4em;

    li {
      display: inline-block;
      color: white;
      opacity: 0.5;
      text-shadow: 0 0 2px black;
      margin: 0 4px;
      transition: all 0.4s;

      &.is-active {
        opacity: 1;
        transition: all 0.4s;
      }
    }
  }

  #identify-screen {
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 80px;
    right: 80px;
    height: 160px;
    width: 240px;
    font-size: 6em;
    color: white;
    padding: 24px;
    z-index: 999;
    text-align: center;
    line-height: 1;
  }

  .pause-indicator {
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    mix-blend-mode: difference;
    pointer-events: none;
    opacity: 0.6;

    .ixo {
      stroke: darkgray;
      fill: darkgray;
      width: 20rem;
      height: 20rem;
    }
  }
}
