@use 'sass:math';

@function rem($value) {
  @if type-of($value) == 'number' and unitless($value) {
    @if ($value == 0) {
      @return $value;
    } @else {
      @return math.div($value, 16) + rem;
    }
  } @else {
    @return $value;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=VT323&display=swap');

@import 'body';
@import 'menu';
@import 'indicators';
@import 'slider';
@import 'slide';
@import 'card';
@import 'hecinfo';
@import 'employees';
@import 'courses';
@import 'weather';
@import 'fonts';
@import 'news';
@import 'setup-container';
@import 'terminal';
@import 'marketing';

html,
body {
  font-size: 16px !important;

  @media (min-width: 121em) {
    font-size: 32px !important;
  }

  font-family: 'Open Sans', sans-serif;
}

.is-hidden {
  visibility: hidden !important;
  opacity: 0 !important;
  display: none !important;
}

.ixo {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}
